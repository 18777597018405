<template>
  <div class="me-login">
    <div class="me-login__header">
      <i class="me-icon-back" @click="$router.go(-1)"></i>
    </div>
    <div class="me-login__content">
      <van-tabs v-model="active" color="#33cc66" class="me-login__content--van">
        <van-tab>
          <i slot="title">{{ $t("login") }}</i>
          <div class="me-login__content--form">
            <span class="avatar">
              <i class="me-icon-user"></i>
            </span>
            <ul>
              <li :class="{ error: userNameError }">
                <i class="me-icon-user"></i>
                <input type="text" v-model="userName" />
              </li>
              <li :class="{ error: passwordError }">
                <i class="me-icon-password"></i>
                <i
                  class="me-icon-help forget"
                  @click="$router.push({ name: 'breakPwd' })"
                ></i>
                <input type="password" v-model="password" />
              </li>
              <li class="btn">
                <van-button
                  type="primary"
                  class="submit"
                  @click="handlerSubmitLogin()"
                  :loading="loading"
                >
                  <i class="me-icon-check"></i>
                </van-button>
              </li>
            </ul>
            <!-- <div class="loginOtherWay">
              <i class="me-icon-qq" @click="otherWayLogin('qq')"></i>
              <i class="me-icon-weibo" @click="otherWayLogin('sina')"></i>
              <i class="me-icon-wechat" @click="otherWayLogin('wechat')"></i>
              <i class="me-icon-linkedin" @click="otherWayLogin('linkedin')"></i>
              <i class="me-icon-facebook" @click="loginGloble('facebook')"></i>
              <i class="me-icon-twitter" @click="loginGloble('twitter')"></i>
            </div> -->
          </div>
        </van-tab>
        <van-tab>
          <i slot="title">{{ $t("register") }}</i>
          <div class="me-login__content--form">
            <ul>
              <li :class="{ error: accountError }">
                <i class="me-icon-user"></i>
                <input type="text" v-model="account" />
              </li>
              <li :class="{ error: registerPwdError }">
                <i class="me-icon-password"></i>
                <input type="password" v-model="registerPwd" />
              </li>
              <li class="btn">
                <van-button
                  type="primary"
                  class="submit"
                  @click="handlerSubmitRegister"
                  :loading="loading"
                >
                  <i class="me-icon-check"></i>
                </van-button>
              </li>
            </ul>
          </div>
        </van-tab>
      </van-tabs>
    </div>
    <van-popup
      v-model="showAddressTree"
      position="left"
      class="me-login--popup"
      :style="{ height: '100%', width: '90%' }"
    >
      <van-tree-select
        :items="regions"
        :active-id.sync="curCountry.value"
        :main-active-index.sync="regionIndex"
        color="#33cc66"
        height="100%"
        @click-item="handlerChangeCountry"
      />
    </van-popup>
  </div>
</template>
<script>
import {
  otherWayLogin,
  fullRegion,
  register,
  registerNew,
  registerByPhone,
  login,
} from "@/api/webApi";
import hello from "hellojs/dist/hello.all.js";
import { env, getBrowserInfo, parseTime } from "@/utils";
import { fnencrypt } from "@/utils/help";
export default {
  data() {
    return {
      active: 0,
      userName: "",
      password: "",
      account: "",
      registerPwd: "",
      countryId: "37",
      regionIndex: 1,
      regions: [],
      showAddressTree: false,
      curCountry: {},
      language: "",
      accountError: false,
      registerPwdError: false,
      loading: false,
      to: "",
      userNameError: false,
      passwordError: false,
    };
  },
  props: {},
  created() {
    this.language = localStorage.getItem("langCode") || "1";
    this.to = this.$route.query.redirect;
  },
  watch: {},
  mounted() {
    hello.init(
      {
        twitter: "NjnU0lG7BZNOvf1wgjlXfxJPA",
        facebook: "2996347000381985",
      },
      {
        display: "page",
        oauth_proxy: "https://auth-server.herokuapp.com/proxy",
      }
    );
  },
  methods: {
    async handlerSubmitLogin(registerParams) {
      let params = {
        userName: this.userName,
        userPassword: fnencrypt(this.password),
        remember: true,
        device: "web",
        language: this.language,
      };
      if (registerParams && Object.keys(registerParams).length) {
        params = registerParams;
      }

      this.loading = true;
      const result = await login(params).catch((e) => {});
      if (result && result.data) {
        const user = result.data[0];
        const userinfo = {
          name: user.name,
          id: user.provider_id,
          token: user.token,
          country: user.country,
          avatar: user.profile_photo,
          gender: user.gender,
          phone: user.phone,
          uc_id: user.uc_id,
        };
        this.$store.dispatch("commitUserinfo", userinfo);

        if (this.to) {
          this.$router.push({
            name: this.to,
            params: { ...this.$route.query },
          });
        } else {
          window.history.go(-1);
        }
      }
      this.loading = false;
    },
    handlerSubmitRegister() {
      this.newHandlerRegister();
    },
    registerCheck() {
      if (!this.account) {
        this.accountError = true;
        this.loading = false;
        return;
      } else {
        this.accountError = false;
      }
      if (!this.registerPwd) {
        this.registerPwdError = true;
        this.loading = false;
        return;
      } else {
        this.registerPwdError = false;
      }
    },
    // 新版注册
    async newHandlerRegister() {
      let params = {
        password: fnencrypt(this.registerPwd),
        username: this.account,
      };
      this.loading = true;
      const result = await registerNew(params).catch((e) => {});
      this.loading = false;
      console.log(result);
      if (result && result.code === 200) {
        this.handlerRegisterSuccess();
      } else {
        this.$toast(result.message);
      }
    },
    async handlerRegisterByEmail() {
      this.loading = true;
      this.registerCheck();
      const params = {
        userName: this.account,
        userPassword: fnencrypt(this.registerPwd),
        table_name_replace: this.language,
        device: "web",
        language: this.language,
      };
      const result = await register(params).catch((e) => {});
      console.log(result, "////email register");
      if (result && result.data) {
        this.handlerRegisterSuccess();
      } else {
        this.$toast(result.message);
      }
      this.loading = false;
    },
    async handlerRegisterByPhone() {
      this.loading = true;
      this.registerCheck();
      const params = {
        phone: this.account,
        password: fnencrypt(this.registerPwd),
        password_again: fnencrypt(this.registerPwd),
        country: this.countryId,
        table_name_replace: this.language,
        device: "web",
        language: this.language,
      };
      const result = await registerByPhone(params).catch((e) => {});
      console.log(result, "////phone register");
      if (result && result.data) {
        this.handlerRegisterSuccess();
      }
      this.loading = false;
    },
    handlerRegisterSuccess() {
      const loginParams = {
        userName: this.account,
        userPassword: fnencrypt(this.registerPwd),
        remember: true,
        device: "web",
        language: this.language,
      };
      this.handlerSubmitLogin(loginParams);
    },
    handlerChangeCountry(data) {
      this.curCountry = data;
      this.countryId = data.value;
      this.showAddressTree = !this.showAddressTree;
    },
    async handlerGetFullRegion() {
      let params = { language: this.language };
      const result = await fullRegion(params).catch((e) => {});
      if (result) {
        this.regions = result.data.data.map((item) => {
          return {
            text: item.regionName,
            value: item.regionId,
            children: item.countries.map((el) => {
              return {
                text: el.countryName,
                id: el.countryId,
                value: el.countryCode,
                phonePrefix: el.phonePrefix,
              };
            }),
          };
        });
        this.regions.unshift({ text: this.$t("all"), value: "" });
        this.regions[1].children.some((item) => {
          if (item.value == "37") this.curCountry = item;
        });
      }
    },
    loginGloble(network) {
      let provider = hello(network);
      provider.login().then(
        (response) => {
          provider.api("me", (resp) => {
            if (resp && resp.id) {
              let { id_str, name, profile_image_url_https, id } = resp;
              this.handlerAutoRegister({
                uuid: id_str || id,
                username: name,
                avatar: profile_image_url_https,
                source: network,
                language: localStorage.getItem("langCode"),
              });
            }
          });
        },
        (e) => {
          if (e.error.code !== "cancelled") {
            console.log(e.error.message);
          }
        }
      );
    },
    async handlerAutoRegister(params) {
      const result = await autoRegister(params).catch((e) => {
        console.log(e);
      });
      if (result && result.data) this.handlerLoginSuccessEmit(result.data.data);
    },
    handlerLoginSuccessEmit(item) {
      let userinfo = {
        id: (item.provider_id || item.providerId).toString(),
        name: item.name,
        account: item.account,
        avatar: item.profile_photo || item.portfolio,
        uc_id: item.uc_id || item.ucId,
        country: item.country,
        gender: item.gender,
        token: item.token,
      };
      this.$store.dispatch("commitUserinfo", userinfo);
    },
    async otherWayLogin(type) {
      const result = await otherWayLogin({
        source: type,
        clientType: "h5",
      }).catch((e) => {});
      if (result && result.data) location.href = result.data.data;
      // console.log(result, "///result.data.data");
    },
  },
};
</script>
<style lang="stylus">
@import '~styles/utils/index';

+b(me-login) {
  background: #ffffff;
  min-height: 100vh;

  .van-overlay {
    top: 0 !important;
  }

  +e(header) {
    height: 54px;
    display: flex;
    align-items: center;
    padding: 0 0.9rem;
  }

  +m(popup) {
    .van-tree-select {
      .van-sidebar-item--select {
        border-color: #33cc66 !important;
      }

      .van-tree-select__item {
        font-weight: normal;
      }

      .van-tree-select__item--active {
        color: #33cc66 !important;
      }
    }
  }

  +e(content) {
    padding: 0rem 1.25rem;

    +m(van) {
      .van-tabs__wrap {
        margin: 0.5rem 0;

        &::after {
          border: none !important;
        }

        .van-tabs__nav {
          width: 80%;
          margin: 0 auto;

          i {
            font-size: 16px;
          }
        }
      }
    }

    +m(form) {
      display: flex;
      flex-flow: column;
      padding: 0.5rem 0;

      .avatar {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 0.5rem;

        i {
          font-size: 36px;
          background: #f5f5f5;
          text-align: center;
          width: 70px;
          height: 70px;
          line-height: 70px;
          border-radius: 70px;
          color: #c5c5c5;
          margin-right: 0.5rem;

          &:last-child {
            margin-right: 0;
          }

          &.active {
            color: #33cc66;
          }
        }

        &.type {
          i {
            width: 65px;
            height: 65px;
            line-height: 65px;
            border-radius: 65px;
            font-size: 24px;
            margin-right: 1.5rem;

            &:last-child {
              margin: 0;
            }
          }
        }
      }

      .loginOtherWay {
        display: flex;
        flex-flow: row;
        justify-content: space-between;
        align-items: center;
        margin: 0.9rem 0;

        i {
          font-size: 28px;

          &.me-icon-qq {
            color: #04c6f1;
          }

          &.me-icon-weibo {
            color: #fb6e65;
          }

          &.me-icon-wechat {
            color: #33cc66;
          }

          &.me-icon-linkedin {
            color: rgb(0, 123, 181);
          }

          &.me-icon-facebook {
            color: rgb(60, 90, 153);
          }

          &.me-icon-twitter {
            color: rgb(85, 172, 238);
          }
        }
      }

      ul {
        margin: 1rem 0;

        li {
          padding: 0;
          line-height: 50px;
          position: relative;
          border-bottom: 1px solid #f5f5f5;
          display: flex;
          flex-flow: row;

          &.error {
            border-color: red;
          }

          &:last-child {
            border: 0;
          }

          &.row {
            span {
              position: absolute;
              left: 30px;
            }

            input {
              text-indent: 4.5rem;
            }
          }

          &.location {
            display: flex;
            flex-flow: row;

            i {
              position: relative;
              margin-right: 0.5rem;

              &.icon {
                position: absolute;
                right: 0;
                font-size: 16px;
              }
            }
          }

          i {
            position: absolute;
            font-size: 22px;
            color: #666666;

            &.forget {
              right: 0;
            }
          }

          input {
            font-size: 16px;
            color: #666666;
            line-height: 50px;
            border: 0;
            width: 100%;
            text-indent: 1.9rem;
          }

          &.btn {
            display: flex;
            flex-flow: column;
            margin-top: .9rem;

            .submit {
              border-radius: 2rem;
              line-height: auto;

              i {
                font-size: 26px;
                position: relative;
              }
            }

            i {
              font-size: 18px;
              color: #ffffff;
            }
          }
        }
      }
    }
  }
}
</style>
