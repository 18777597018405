var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "me-login" },
    [
      _c("div", { staticClass: "me-login__header" }, [
        _c("i", {
          staticClass: "me-icon-back",
          on: {
            click: function($event) {
              return _vm.$router.go(-1)
            }
          }
        })
      ]),
      _c(
        "div",
        { staticClass: "me-login__content" },
        [
          _c(
            "van-tabs",
            {
              staticClass: "me-login__content--van",
              attrs: { color: "#33cc66" },
              model: {
                value: _vm.active,
                callback: function($$v) {
                  _vm.active = $$v
                },
                expression: "active"
              }
            },
            [
              _c("van-tab", [
                _c("i", { attrs: { slot: "title" }, slot: "title" }, [
                  _vm._v(_vm._s(_vm.$t("login")))
                ]),
                _c("div", { staticClass: "me-login__content--form" }, [
                  _c("span", { staticClass: "avatar" }, [
                    _c("i", { staticClass: "me-icon-user" })
                  ]),
                  _c("ul", [
                    _c("li", { class: { error: _vm.userNameError } }, [
                      _c("i", { staticClass: "me-icon-user" }),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.userName,
                            expression: "userName"
                          }
                        ],
                        attrs: { type: "text" },
                        domProps: { value: _vm.userName },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.userName = $event.target.value
                          }
                        }
                      })
                    ]),
                    _c("li", { class: { error: _vm.passwordError } }, [
                      _c("i", { staticClass: "me-icon-password" }),
                      _c("i", {
                        staticClass: "me-icon-help forget",
                        on: {
                          click: function($event) {
                            return _vm.$router.push({ name: "breakPwd" })
                          }
                        }
                      }),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.password,
                            expression: "password"
                          }
                        ],
                        attrs: { type: "password" },
                        domProps: { value: _vm.password },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.password = $event.target.value
                          }
                        }
                      })
                    ]),
                    _c(
                      "li",
                      { staticClass: "btn" },
                      [
                        _c(
                          "van-button",
                          {
                            staticClass: "submit",
                            attrs: { type: "primary", loading: _vm.loading },
                            on: {
                              click: function($event) {
                                return _vm.handlerSubmitLogin()
                              }
                            }
                          },
                          [_c("i", { staticClass: "me-icon-check" })]
                        )
                      ],
                      1
                    )
                  ])
                ])
              ]),
              _c("van-tab", [
                _c("i", { attrs: { slot: "title" }, slot: "title" }, [
                  _vm._v(_vm._s(_vm.$t("register")))
                ]),
                _c("div", { staticClass: "me-login__content--form" }, [
                  _c("ul", [
                    _c("li", { class: { error: _vm.accountError } }, [
                      _c("i", { staticClass: "me-icon-user" }),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.account,
                            expression: "account"
                          }
                        ],
                        attrs: { type: "text" },
                        domProps: { value: _vm.account },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.account = $event.target.value
                          }
                        }
                      })
                    ]),
                    _c("li", { class: { error: _vm.registerPwdError } }, [
                      _c("i", { staticClass: "me-icon-password" }),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.registerPwd,
                            expression: "registerPwd"
                          }
                        ],
                        attrs: { type: "password" },
                        domProps: { value: _vm.registerPwd },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.registerPwd = $event.target.value
                          }
                        }
                      })
                    ]),
                    _c(
                      "li",
                      { staticClass: "btn" },
                      [
                        _c(
                          "van-button",
                          {
                            staticClass: "submit",
                            attrs: { type: "primary", loading: _vm.loading },
                            on: { click: _vm.handlerSubmitRegister }
                          },
                          [_c("i", { staticClass: "me-icon-check" })]
                        )
                      ],
                      1
                    )
                  ])
                ])
              ])
            ],
            1
          )
        ],
        1
      ),
      _c(
        "van-popup",
        {
          staticClass: "me-login--popup",
          style: { height: "100%", width: "90%" },
          attrs: { position: "left" },
          model: {
            value: _vm.showAddressTree,
            callback: function($$v) {
              _vm.showAddressTree = $$v
            },
            expression: "showAddressTree"
          }
        },
        [
          _c("van-tree-select", {
            attrs: {
              items: _vm.regions,
              "active-id": _vm.curCountry.value,
              "main-active-index": _vm.regionIndex,
              color: "#33cc66",
              height: "100%"
            },
            on: {
              "update:activeId": function($event) {
                return _vm.$set(_vm.curCountry, "value", $event)
              },
              "update:active-id": function($event) {
                return _vm.$set(_vm.curCountry, "value", $event)
              },
              "update:mainActiveIndex": function($event) {
                _vm.regionIndex = $event
              },
              "update:main-active-index": function($event) {
                _vm.regionIndex = $event
              },
              "click-item": _vm.handlerChangeCountry
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }